<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    class="managePrivilege">
    <div class="simpleInfoPanel user-select-wrap">
      <el-select
        v-model="brand"
        @change="selectUser"
        popper-class="w-block-select-down"
        placeholder="请选择">
        <el-option
          v-for="item in brandOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="simpleInfoPanel mt15">
      <ul class="filtrate">
        <span>业态类别 ：</span>
        <li
          v-for="(item, index) in businessType"
          :key="index"
          :class="{'activeItem': businessTypeId === item.id}"
          @click="typeSelect(item.id)"
          >{{item.item}}</li>
      </ul>
      <ul class="filtrate">
        <span>面积要求 ：</span>
        <li
          v-for="(item, index) in areaType"
          :key="index"
          :class="{'activeItem': areaId === item.id}"
          @click="areaSelect(item.id)"
          >{{item.item}}</li>
      </ul>
    </div>
    <div class="simpleInfoPanel mt15" style="padding:0">
      <ul class="tabs">
        <li
          v-for="(item, index) in singleSortingWay"
          :key="index"
          :class="{'acitveItem': inviteState === item.id}"
          @click="handleTabs(item.id)"
          >{{item.item}}</li>
      </ul>
    </div>
    <div>
      <div class="message-wrap">
        <i class="icon-msg"></i>
        <p>
          联系特权说明：支持对联系方式正处于建设阶段的连锁品牌发起寻找联系人的特权，赢商会通过自有渠道资源在3个工作日内提供品牌拓展联系方式（连锁品牌指：带有“连锁品牌”标签的品牌)
        </p>
        <p class="tips">
          ( 注：1、客户提交需求后的3个工作日内，将在“联系特权管理”提醒最新的反馈结果；2、对约定时间内未能提供有效拓展联系人或由客户自主取消的找寻需求，系统会返还已扣除的1个特权量；3、品牌库在持续更新中，支持客户查看历史发起但当时未反馈有联系方式的品牌，且不扣除权益量 )
        </p>
      </div>
    </div>
    <div>
      <ul class="contentList mt15">
        <li
          v-for="(item, index) in brandList.dataList"
          :key="index"
          @click="toDetail(item.brandId, item.gbid)"
          class="shadowPanel">
          <img :src="item.brandLogo" width="86" height="86">
          <h2>{{item.brandName}}</h2>
          <p class="text">{{item.typeName ? item.typeName + '/': ''}}{{item.areaMin ? item.areaMin: ''}}{{item.areaMax ? '-' + item.areaMax + '平米': ''}} {{item.shopNum}}</p>
          <p class="add-time">
            <span>发起时间：{{item.addTime}} {{item.addUser ? '— ' + item.addUser: ''}}{{item.userName?'(' + item.userName + ')': ''}}{{item.userTel?item.userTel: ''}}</span>
            <span v-if="item.lookStatus === 1"> {{item.lookEndTime}}反馈搜寻结果</span>
            <span v-if="item.lookStatus === 42">已于{{item.cancelTime}}取消 {{item.cancelUser ? '— ' + item.cancelUser: ''}}</span>
          </p>
          <span class="cancal" @click.stop="toUpdateState(item.brandId, '42', item.gbid, item.uid)" v-if="item.lookStatus === 1">取消邀约</span>
          <span @click.stop="toContact(item.brandId, item.gbid)" v-if="item.lookStatus === 3" class="look-contact"><i class="icon-tel-while"></i>查看品牌联系方式</span>
          <span class="searchRes cancelSpan" v-if="item.lookStatus === 42">已取消</span>
          <span class="searchRes" v-if="item.lookStatus === 41">搜寻失败</span>
          <span class="searchRes" v-if="item.lookStatus === 3">搜寻成功</span>
        </li>
      </ul>
      <div class="no-data" v-if="brandList.dataList && brandList.dataList.length === 0">
        <img src="@/assets/images/Jurisdiction.png" alt="">
        <p>暂无相关记录</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'managePrivilege',
  components: {},
  data () {
    return {
      loading: true,
      brand: '全部邀约品牌',
      brandOptions: [],
      businessType: [],
      businessTypeId: '',
      areaType: [],
      areaId: '',
      singleSortingWay: [],
      inviteState: 0,
      contentOptions: [],
      pageNum: 1,
      screen: 0,
      brandList: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.init()
    this.fetchListData()
  },
  methods: {
    init () {
      this.axios.post(api.inviteSearchFour, { openingStatus: 0, userid: this.user.userid })
        .then((res) => {
          // 业务类型
          this.businessType = res.data.data.industryType
          this.businessType.unshift({ item: '不限', id: '' })
          // 面积要求
          this.areaType = res.data.data.areaRequired
          this.areaType.unshift({ item: '不限', id: '' })
          // 单选排序
          this.singleSortingWay = res.data.data.singleSortingWay
          this.singleSortingWay.unshift({ item: '全部', id: 0 })
          // 账号邀约
          this.brandOptions = res.data.data.userList
        })
    },
    typeSelect (idx) {
      this.businessTypeId = idx
      this.fetchListData()
    },
    areaSelect (id) {
      this.areaId = id
      this.fetchListData()
    },
    handleTabs (item) {
      this.inviteState = item
      this.fetchListData()
    },
    selectUser (val) {
      this.screen = val
      this.fetchListData()
    },
    fetchListData () {
      const params = {
        pageNum: 1,
        pageSize: 20,
        invite_state: this.inviteState,
        formats_id: this.businessTypeId,
        area_id: this.areaId,
        screen_userid: this.screen
      }
      this.axios.post(api.inviteBrandList, params)
        .then((res) => {
          this.brandList = res.data.data
          this.loading = false
        })
    },
    toUpdateState (id, state, gbid, userid) {
      this.$confirm('确定取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'invitePosition'
      }).then(() => {
        const params = {
          brandId: id,
          state: state,
          login_userid: 4,
          gbid: gbid,
          userid: userid
        }
        this.axios.post(api.updateStoreState, params)
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '取消成功！'
              })
              this.fetchListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      })
    },
    toDetail (id, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=5&questionState=0' + '&gbid=' + gbid, '_blank')
    },
    toContact (id, gbid) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: id,
          source: 5,
          from: 'contactList',
          questionState: 1,
          gbid: gbid
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    }
  }
}
</script>

<style scoped lang="stylus">
.managePrivilege
  font-size 13px
  .filtrate,.tabs
    li
      display inline-block
      cursor pointer
  .filtrate
    margin 20px  0 0 24px
    li
      display inline-block
      cursor pointer
      margin 0 15px
      padding 4px 0
    li.activeItem
      border-bottom 1px solid #ffa134
  .tabs
    li
      height 40px
      line-height 40px
      padding 0 20px
    li.acitveItem
      background #3a3c43
  .contentList
    display flex
    justify-content space-between
    flex-wrap wrap
    width 99.3%
    padding 0
    li
      width 47.1%
      padding 14px 14px
      border 1px solid #484a50
      margin-bottom 15px
      position relative
      cursor pointer
      img
        float left
        width 86px
        height 86px
        overflow hidden
        display inline-block
        border-radius 3px
        margin-right 14px
      h2
        font-size 18px
      p
        margin-top 22px
        span:first-child
          margin-right 35px
          &:before
            background #fea134
        span:before
          content ''
          display inline-block
          width 6px
          height 6px
          position relative
          top -2px
          border-radius 6px
          background #20d6fe
          margin-right 4px
      p.text
        color #999
        margin-top 12px
      .el-button
        position absolute
        right 29px
        top 41px
      .btnCommon
        background #ffa134
        color #ffa134 !important
      .btnPrimary
        color #fff !important
      .searchRes
        position absolute
        right 0
        top 0
        display inline-block
        width 70px
        line-height 26px
        height 26px
        text-align center
        color #0ab72e
        background linear-gradient(to right,rgba(39,41,48,0),rgba(36,80,58,1))
      .failSpan
        color #b94811
        background linear-gradient(to right,rgba(39,41,48,0),rgba(83,54,51,1))
      .cancelSpan
        color #fff
        background linear-gradient(to right,rgba(157,158,162,0),rgba(157,158,162,0.6))
  .add-time
    position absolute
    bottom 10px
    left 116px
  .cancal
    position absolute
    right 20px
    top 42px
    background-color #ffa134
    width 80px
    height 27px
    text-align center
    line-height 27px
    color #fff
    border-radius 3px
    cursor pointer
    z-index 11
  .look-contact
    position absolute
    right 20px
    top 42px
    padding 5px 12px 5px 12px
    color #333333
    cursor pointer
    border 1px solid #ffa134
    color #ffa134
    border-radius 3px
    cursor pointer
    z-index 11
    .icon-tel-while:before
      font-size 10px
      color #FFA134
      margin-right 8px
.no-data
  text-align center
  img
    width 158px
    height 102px
  p
    color #ffffff
    font-size 14px
    margin-top 20px
.message-wrap
  background #272930
  margin-top 20px
  padding 8px
  position relative
  width 98%
  i
    font-size 16px
    position absolute
    left 9px
    top 9px
  p
    color #ffffff
    font-size 14px
    padding-left 25px
    i:before
      color #fff
  .tips
    color rgba(255,255,255,0.5)
    margin-top 3px
    // margin-left 20px
    line-height 20px
    font-size 12px
    // padding-right 15px
.user-select-wrap
  padding-top 10px
  .el-select
    margin-left 15px
</style>
